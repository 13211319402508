<div class="parent">
  <p class="help">
    Complete your order at one of our 4500+ independently owned retailers.
  </p>

  <button
    data-cy="order-in-store"
    mat-flat-button
    color="primary"
    (click)="onInStore()"
    class="gtag-in-store-create"
  >
    ORDER IN-STORE
  </button>

  <p style="margin-bottom: 0px">- or -</p>

  <!-- onlineEnabled -->
  <div class="online-enabled">
    Order online! Online orders must be submitted at least 3 weeks (21 days)
    prior to event date.
  </div>

  <!-- <div *ngIf="!onlineEnabled" class="online-disabled">
    <strong
      >* Your event date is too soon. Online ordering is unavailable for events
      less than two-weeks from time of ordering</strong
    >
  </div> -->

  <button
    mat-flat-button
    color="primary"
    (click)="onOnline()"
    [disabled]="!onlineEnabled"
    data-cy="order-online"
    style="margin-top: 20px"
    class="gtag-online-create"
  >
    <!-- *ngIf="onlineEnabled" -->
    <span>ORDER ONLINE</span>
    <!-- <span *ngIf="!onlineEnabled">ONLINE ORDERING UNAVAILABLE*</span> -->
  </button>
  <div *ngIf="!onlineEnabled" class="online-disabled">
    <strong>*Your event date is too soon</strong>
  </div>
</div>

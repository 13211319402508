import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  convertUnixTimestampToDate,
  getOnlineLeadTimeDays,
  getSelectedStoreFromLocalStorage,
} from 'business-logic';
import { DealerAccountElastic, Event } from 'common-types';
import dayjs from 'dayjs';

@Component({
  selector: 'app-online-in-store',
  templateUrl: './online-in-store.component.html',
  styleUrls: ['./online-in-store.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule],
})
export class OnlineInStoreComponent implements OnInit {
  @Input() event!: Event;
  @Output() setEvent = new EventEmitter<Event>();
  @Output() setStepNumber = new EventEmitter<number>();

  onlineEnabled = false;

  constructor() {}

  ngOnInit(): void {
    const eventDate = convertUnixTimestampToDate(this.event.eventDate);
    eventDate.setHours(0, 0, 0, 0);
    const parsedDate = dayjs(eventDate);

    const newDate: Date = new Date();
    newDate.setHours(0, 0, 0, 0);
    newDate.setDate(newDate.getDate() + getOnlineLeadTimeDays());

    const dateDiff: number = parsedDate.diff(newDate);

    if (dateDiff >= 0) {
      this.onlineEnabled = true;
    }
  }

  onOnline(): void {
    this.event.inStoreInfo = undefined;
    this.setEvent.emit(this.event);
    this.setStepNumber.emit(5);
  }

  getSelectedStoreFromLocalStorage(): DealerAccountElastic | null {
    const selectedStore = getSelectedStoreFromLocalStorage();
    return selectedStore;
  }

  onInStore(): void {
    this.event.inStoreInfo = { isCreatedByDealer: false };
    if (
      this.getSelectedStoreFromLocalStorage() !== null &&
      this.getSelectedStoreFromLocalStorage() !== undefined
    ) {
      const store = getSelectedStoreFromLocalStorage();
      if (store !== null) {
        this.event.inStoreInfo.store = store;
      }
    }
    this.setEvent.emit(this.event);
    this.setStepNumber.emit(5);
  }
}

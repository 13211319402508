<form [formGroup]="dateForm" (ngSubmit)="onSubmit()">
  <h3>When is the event?</h3>
  <div
    style="text-align: left; width: 100%; margin-bottom: 10px; font-size: 14px"
  >
    * Required
  </div>
  <app-date-form
    (valueChange)="setDate($event)"
    [hideRequiredMarker]="false"
    [initialValue]="existingEventDate()"
    [hideHint]="true"
    label="Event Date"
    appearance="outline"
    [maxDate]="maxDate"
    [minDate]="minDate"
  >
  </app-date-form>
  <button [disabled]="dateForm.invalid" data-cy="next" mat-flat-button color="primary">Next</button>
</form>
